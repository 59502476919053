/// <reference path="../../../typings/jquery/jquery.d.ts"/>
/// <reference path="../../../typings/materialize-css/materialize-css.d.ts"/>

class Sitters {
    private lv: LifeVine;
    private list;
    private userListUl;
    private info;
    private userForm;
    private currentId;
    private criteria;
    private locations;

    constructor(lv: LifeVine) {
        this.lv = lv;

        this.userForm = jQuery('#form_sitter');
        this.userListUl = jQuery('#sitter_list');
        this.info = jQuery('#sitter_info');
        this.criteria = jQuery('#sitter_criteria');
        this.locations = this.userForm.find('#sitter_locations');

        this.userForm.find('select').material_select();

        this.userListUl.on('click', 'a', event => {
            event.preventDefault();
            event.stopPropagation();

            let id = $(event.target).closest('a').data('id');
            this.getUser(id);
        });

        this.prepAgeSelects();
        this.refreshList();
        this.newUser();

        this.userForm.on('submit', event => {
            event.preventDefault();
            event.stopPropagation();

            this.submitUser();
        });

        jQuery('#sitter_add').click(event => {
            event.preventDefault();
            event.stopPropagation();

            this.newUser();
        });

        this.userForm.find('#sitter_reset_password').click(event => this.resetPassword(event));

        this.locations.find('#sitter_add_location').click(event => this.addLocation(event));
        this.locations.on('click', 'a.make-primary', event => this.makePrimaryLocation(event));
        this.locations.on('click', 'a.edit-location', event => this.editLocation(event));
        this.locations.on('click', 'a.delete-location', event => this.deleteLocation(event));

        this.criteria.on('click', '.criterion-add', event => this.addCriterion(event));
        this.criteria.on('click', '.criterion-delete', event => this.removeCriterion(event));
    }

    private prepAgeSelects() {
        let minMax = this.userForm.find('[name="min_age"],[name="max_age"]');
        let minMaxMonthsArray = [0.03, 0.06, 0.09, 0.12, 0.15, 0.18, 0.21];
        let minMaxYearsArray = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

        let options = [
            {name: 'Any age', value: ''},
        ];
        minMaxMonthsArray.forEach(months => {
            options.push({
                name: (months * 100).toString() + ' months',
                value: months.toString()
            });
        });
        minMaxYearsArray.forEach(years => {
            options.push({
                name: years.toString(),
                value: years.toString()
            });
        });

        options.forEach(option => {
            minMax.append(`<option value="${option.value}">${option.name}</option>`);
        });
    }

    public refreshList() {
        this.lv.sitters().get()
            .done(data => {
                this.list = data;
                this.renderList();
            });
    }

    private newUser() {
        this.currentId = null;
        let data = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            phone_text: false,
            user_type: 'sitter',
            active: true,
            min_age: '',
            max_age: '',
            bio: ''
        };

        this.userForm.find('#sitter_reset_password').prop('disabled', true);
        this.criteria.hide();
        this.locations.hide();
        setFormData(this.userForm, data);
        this.userForm.find('select').material_select();
        Materialize.updateTextFields();
    }

    private editUser(user) {
        this.currentId = user.id;
        setFormData(this.userForm, user);
        this.userForm.find('select').material_select();
        this.criteria.show();
        this.locations.show();
        Materialize.updateTextFields();
        this.userForm.find('#sitter_reset_password').prop('disabled', false);

        if ($('body').hasClass('user-admin')) {
            this.getLocations();
            this.getCriteria();
        }
    }

    private showUser(user) {
        let texting = user.phone_text ? '(allows texting)' : '(no texting please)';
        this.info.html(`<p>Name: ${user.first_name} ${user.last_name}</p>
<p>Email: <a href="mailto:${user.email}">${user.email}</a></p>
<p>Phone: ${user.phone} ${texting}</p>
<p>${user.bio}</p>`);
    }

    private showCriteria(criteria: any[], options: any[]) {
        let template = Handlebars.compile(jQuery('#client-criteria-template').html());
        this.criteria.find('ul').html(template(criteria));

        let ids = criteria.map(criterion => {
            return criterion['id'];
        });
        let select = this.criteria.find('select').html('');
        options.forEach(option => {
            if (ids.indexOf(option['id']) < 0) {
                select.append(`<option value="${option['id']}">${option['name']}</option>`);
            }
        });
        select.material_select();
    }

    private showLocations(locations) {
        this.locations.find('.location').remove();
        locations.forEach(location => {
            let content = '<div class="col s12 l6 location">'
                + location.line1 + '<br>'
                + (location.line2 ? location.line2 + '<br>' : '')
                + location.city + ' ' + location.state + ' ' + location.zip + '<br>'
                + (location.primary ?
                    'Primary' :
                    `<a href="#" data-location-id="${location.id}" class="make-primary">Make primary</a>`) + ' | '
                + `<a href="#" data-location-id="${location.id}" class="edit-location">Edit</a>`
                + (location.primary ?
                    '' :
                    ` | <a href="#" data-location-id="${location.id}" class="delete-location">Delete</a>`)
                + '</div>';
            this.locations.append(content);
        });
    }

    private submitUser() {
        let formData = getFormData(this.userForm);

        if (this.currentId) {
            this.lv.sitters().save(this.currentId, formData)
                .done(data => {
                    if (data.success === true) {
                        Materialize.toast('Sitter updated', 2000);
                        this.newUser();
                        this.refreshList();
                    } else {
                        Materialize.toast('Sitter not updated', 2000);
                        this.setErrors(data.errors);
                    }
                });
        } else {
            this.lv.sitters().create(formData)
                .done(data => {
                    if (data.success === true) {
                        this.newUser();
                        this.refreshList();
                        Materialize.toast('Sitter created', 2000);
                        if (formData['active']) {
                            this.getUser(data.id);
                        }
                    } else {
                        Materialize.toast('Sitter not created', 2000);
                        this.setErrors(data.errors);
                    }
                });
        }
    }

    private resetPassword(event) {
        this.lv.sitters().resetPassword(this.currentId)
            .done(data => {
                if (data.success === true) {
                    alert('Email sent.');
                } else {
                    alert(data.errors);
                }
            });
    }

    private setErrors(errors) {
        jQuery.each(errors, (field, message) => {
            this.userForm.find(`[for="${field}"]`).attr('data-error', message);
            this.userForm.find(`#${field}`).removeClass('valid').addClass('invalid');
        });
        // errors.forEach()
        console.log(errors);
    }

    private renderList() {
        this.userListUl.html('');
        this.list.forEach(user => {
            this.userListUl.append(Sitters.createUserListItem(user));
        });
    }

    private static createUserListItem(user) {
        let content = '<i class="material-icons circle">person</i>' +
            `<span class="title">${user.first_name} ${user.last_name}</span>` +
            `<a href="#!" class="secondary-content" data-id="${user.id}">` +
            `<i class="material-icons user-admin">edit</i><i class="material-icons user-client">remove_red_eye</i></a>`;
        return jQuery('<li class="collection-item avatar"></li>').append(content);
    }

    private addCriterion(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        let criterion_id = this.criteria.find('select').val();
        if (!criterion_id) {
            return;
        }
        this.lv.sitters().criteria(this.currentId).create({criterion_id: criterion_id})
            .done(() => this.getCriteria())
            .fail(xhr => {
                if (xhr.responseJSON) {
                    Materialize.toast(xhr.responseJSON.error, 2000);
                } else {
                    Materialize.toast('Unable to add capability at this time', 2000);
                }
            });
    }

    private removeCriterion(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        let li = jQuery(event.target).closest('li');
        let criterion_id = parseInt(li.attr('data-id'), 10);
        let name = li.attr('data-name');
        if (!confirm(`Do you want to remove "${name}" from this sitter?`)) {
            return;
        }
        this.lv.sitters().criteria(this.currentId).remove(criterion_id)
            .done(() => this.getCriteria())
            .fail(xhr => {
                if (xhr.responseJSON) {
                    Materialize.toast(xhr.responseJSON.error, 2000);
                } else {
                    Materialize.toast('Unable to remove capability at this time', 2000);
                }
            });

    }

    private addLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        new LocationModal(this.lv.sitters().locations(this.currentId), null, () => {
            this.getLocations();
        });
    }

    private editLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let locationId = jQuery(event.target).data('location-id');
        new LocationModal(this.lv.sitters().locations(this.currentId), locationId, () => {
            this.getLocations();
        });
    }

    private deleteLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let locationId = jQuery(event.target).data('location-id');
        this.lv.sitters().locations(this.currentId).remove(locationId)
            .done(() => {
                this.getLocations();
            });
    }

    private makePrimaryLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let locationId = jQuery(event.target).data('location-id');
        this.lv.sitters().locations(this.currentId).makePrimary(locationId)
            .done(() => {
                Materialize.toast('Primary location updated', 3000);
                this.getLocations();
            });
    }

    private getUser(userId) {
        this.lv.sitters().get(userId)
            .done(user => {
                if ($('body').hasClass('user-admin')) {
                    this.editUser(user);
                } else {
                    this.showUser(user);
                }
            });
    }

    private getCriteria() {
        this.lv.criteria().get()
            .done(options => {
                this.lv.sitters().criteria(this.currentId).get()
                    .done(criteria => this.showCriteria(criteria, options));
            });
    }

    private getLocations() {
        this.lv.sitters().locations(this.currentId).get()
            .done(locations => {
                this.showLocations(locations);
            });
    }
}

window.app.page('page-sitters', () => {
    return params => {
        if (window.controllers.sitters) {
            window.controllers.sitters.refreshList();
        } else {
            window.controllers.sitters = new Sitters(window.lifeVine);
        }
    };
});